import React, { useEffect, useState } from 'react';
import { Grid2 } from '@mui/material';

import Button from '../components/ButtonS.jsx';
import Title from '../components/Title.jsx';
import Loader from '../components/Loader.jsx';
import Error from '../components/Error.jsx';
import HiddenItem from '../components/HiddenItem.jsx';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { fetchApiMethods } from '../api/getMethods';
import errorMessageHandler from '../shared/utils/errorMessageHandler.js';

const Trash = () => {
  const navigate = useNavigate();

  const [api, setApi] = useState({});
  const [error, setError] = useState('');
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  async function fetchItems() {
    try {
      const api = await fetchApiMethods();
      setApi(api);
      const { items } = await api.item.getRemoved();
      setLoading(false);
      return items;
    } catch (e) {
      setLoading(false);
      navigate(`/error`);
      setError(errorMessageHandler(e));
      return [];
    }
  }

  useEffect(() => {
    async function fetchData() {
      const itemList = await fetchItems();
      setItems(itemList);
    }
    fetchData();
  }, []);

  const handleRestoreClick = async (id) => {
    setLoading(true);
    try {
      const updatedItemList = items.filter((item) => item.id !== id);
      setItems(updatedItemList);
      await api.item.restore({ id });
    } catch (e) {
      navigate(`/error`);
      setError(errorMessageHandler(e));
    } finally {
      setLoading(false);
    }
  };
  const handleEmptyTrash = async () => {
    try {
      setLoading(true);
      await Promise.all(
        items.map(async (item) => {
          await api.value.deleteByItemId({ id: item.id });
        }),
      );
      setItems([]);
    } catch (e) {
      navigate(`/error`);
      setError(errorMessageHandler(e));
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <div>
      <Grid2 item="true" xs={12} paddingBottom={2}>
        <Title text={<FormattedMessage id="Trash" />} />
      </Grid2>
      {items.length ? (
        <Grid2 item="true" xs={12} textAlign="center">
          <div style={{ height: '70vh', overflow: 'auto' }}>
            {items.map((item) => (
              <HiddenItem
                key={item.id}
                title={item.title}
                handleClick={() => handleRestoreClick(item.id)}
                restore={true}
              />
            ))}
          </div>
        </Grid2>
      ) : (
        <Title color="gray" text={<FormattedMessage id="NoItems" />} />
      )}
      <Grid2
        item="true"
        xs={12}
        textAlign="center"
        paddingTop={2}
        display="flex"
        justifyContent="center"
        gap={3}
      >
        <Button onClick={() => navigate('/menu')}>
          {<FormattedMessage id="Back" />}
        </Button>
        <Button
          disabled={items?.length > 0 ? false : true}
          onClick={() => handleEmptyTrash()}
        >
          {<FormattedMessage id="EmptyTrash" />}
        </Button>
      </Grid2>
      {error && <Error text={error} />}
    </div>
  );
};

export default Trash;
