/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Grid from '@mui/material/Grid';

import Button from '../components/ButtonS.jsx';
import Loader from '../components/Loader.jsx';
import Title from '../components/Title.jsx';

import { fetchApiMethods } from '../api/getMethods';

import { AuthContext } from '../context/auth';
import { UserContext } from '../context/user.js';
import { styled } from '@mui/system';
import { Tooltip } from '@mui/material';

const Menu = () => {
  const navigate = useNavigate();
  const { setIsAuth } = useContext(AuthContext);
  const { setDate } = useContext(UserContext);

  const [api, setApi] = useState({});
  const [loading, setLoading] = useState(true);
  const [premium, setPremium] = useState();

  const [admin, setAdmin] = useState(false);
  const [userCount, setUserCount] = useState();
  const [regOn, setRegOn] = useState(false);
  const [regOff, setRegOff] = useState(false);

  const TooltipInPremium = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .MuiTooltip-tooltip`]: {
      background: 'linear-gradient(45deg, #388e3c, #81c784)',
      color: '#FF0',
      borderRadius: '8px',
      padding: '5px',
      fontSize: '1rem',
    },
  }));

  useEffect(() => {
    async function fetchData() {
      try {
        const api = await fetchApiMethods();
        setApi(api);
        const adminEmail = localStorage.getItem('adminEmail');
        const disableRegistration =
          localStorage.getItem('disableRegistration') === 'true';
        if (disableRegistration) setRegOn(true);
        else setRegOff(true);

        if (adminEmail && localStorage.getItem('email') === adminEmail) {
          const userCount = await api.user.count();
          setUserCount(userCount.count);
          setAdmin(true);
        }
        const { status } = await api.user.getStatus();
        if (!status) setIsAuth(false);
        else setPremium(status.premium);
      } catch (error) {
        // TEMP CODE
        console.log('TEMP ERROR');
        console.log(error);
        // =================
        navigate(`/error`);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  if (loading) return <Loader />;

  const buttons = [
    { id: 'today', label: <FormattedMessage id="Items" />, disabled: false },
    {
      id: 'profile',
      label: <FormattedMessage id="Profile" />,
      disabled: false,
    },
    {
      id: 'settings',
      label: <FormattedMessage id="Settings" />,
      disabled: false,
    },
    {
      id: 'export',
      label: <FormattedMessage id="Export" />,
      disabled: premium ? false : true,
    },
    {
      id: 'import',
      label: <FormattedMessage id="Import" />,
      disabled: premium ? false : true,
    },
    { id: 'about', label: <FormattedMessage id="About" />, disabled: false },
    {
      id: 'trash',
      label: <FormattedMessage id="Trash" />,
      disabled: premium ? false : true,
    },
  ];

  const handlerFunctions = new Map([
    [
      'today',
      () => {
        setDate(new Date());
        navigate('/activities');
      },
    ],
    [
      'profile',
      () => {
        navigate('/profile');
      },
    ],
    [
      'settings',
      () => {
        navigate('/settings');
      },
    ],
    [
      'export',
      () => {
        navigate('/export');
      },
    ],
    [
      'import',
      () => {
        navigate('/import');
      },
    ],
    [
      'about',
      () => {
        navigate('/about');
      },
    ],
    [
      'trash',
      () => {
        navigate('/trash');
      },
    ],
  ]);

  const handleButtonClick = (id) => {
    handlerFunctions.get(id)();
  };
  const handleRegOn = async () => {
    await api.settings.update({ id: 1, disableRegistration: false });
    setRegOff(true);
    setRegOn(false);
  };
  const handleRegOff = async () => {
    await api.settings.update({ id: 1, disableRegistration: true });
    setRegOff(false);
    setRegOn(true);
  };

  return (
    <Grid container spacing={2}>
      <Title text={<FormattedMessage id="Menu" />} />
      {buttons.map((button) => (
        <Grid item xs={12} textAlign="center" key={button.id}>
          <TooltipInPremium
            title={
              button.disabled ? (
                <FormattedMessage id="AvailableInPremium" />
              ) : (
                ''
              )
            }
            disableInteractive
          >
            <span>
              <Button
                key={button.id}
                disabled={button.disabled}
                onClick={() => handleButtonClick(button.id)}
              >
                {button.label}
              </Button>
            </span>
          </TooltipInPremium>
        </Grid>
      ))}
      {admin && (
        <Grid item xs={12} textAlign={'center'}>
          <div>ADMIN SECTION</div>
          <div>Registration process:</div>
          <div
            style={{
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <Button disabled={regOn} onClick={handleRegOff}>
              Switch OFF
            </Button>
          </div>
          <div
            style={{
              paddingBottom: 10,
            }}
          >
            <Button disabled={regOff} onClick={handleRegOn}>
              Turn ON
            </Button>
          </div>
          <div>users: {userCount}</div>
        </Grid>
      )}
    </Grid>
  );
};

export default Menu;
